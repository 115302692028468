import React from "react";

function b64EncodeUnicode(str) {
  return btoa(encodeURIComponent(str));
}

const FormWidget = (props) => {
  const iframeStyles = {
    width: "100%",
    height: "100%",
    transition: "opacity 0.5s linear",
    backgroundColor: "#fff",
    border: "none",
  };
  const formId = props.formId;

  const newParams = {
    ...props,
    params: props["params"] ? props["params"].replace(/[\r\n]/g, "") : [],
  };
  delete newParams["formId"];
  delete newParams["origin"];
  const widgetParams = b64EncodeUnicode(JSON.stringify(newParams));
  //console.log("FormWidget passing params ", JSON.stringify(newParams));
  return (
    <iframe
      id={"dynapictures-form-frame-" + formId}
      src={props.origin + "/app/widgets/form/" + formId + "?widget_params=" + widgetParams}
      style={iframeStyles}
    />
  );
};

export default FormWidget;

import React from "react";
import ReactDOM from "react-dom";
import FormWidget from "./FormWidget";

const skipAttributes = ["class", "style"];

const attrToObj = (attrs) => {
  const attrsObj = {};
  const length = attrs.length;
  for (let i = 0; i < length; i++) {
    const { name, value } = attrs[i];
    if (skipAttributes.find((item) => item === name)) {
      continue;
    }
    attrsObj[parseKey(name)] = parseValue(value);
  }
  return attrsObj;
};

const parseKey = (value) => {
  var camelCased = value.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
  return camelCased;
};

const parseValue = (value) => {
  if (value === "" || value === "true") {
    return true;
  }

  if (value === "false") {
    return false;
  }

  if (Number(value).toString() === value) {
    return Number(value);
  }

  return value;
};
if (!window.gDynaFormWidget) {
  window.gDynaFormWidget = {};
}
window.gDynaFormWidget.updateLayers = (formId, params) => {
  const iframe = document.getElementById("dynapictures-form-frame-" + formId);
  if (!iframe) {
    throw Error("Failed to find widget by formId " + formId);
  }
  iframe.contentWindow.postMessage({ type: "dynapictures/widgets/form/params", params }, "*");
};

window.gDynaFormWidget.addEventListener = (eventName, callback) => {
  if (eventName === "dialog_closed") {
    window.addEventListener("message", function (event) {
      //if (event.origin !== "https://dynapictures.com") return;
      const { type, payload } = event.data;

      if (type === "dynapictures/widgets/form/events/dialog_closed") {
        //console.log("dialog_closed:", payload);
        callback && callback(payload);
      }
    });
  }
  if (eventName === "generation_finished") {
    window.addEventListener("message", function (event) {
      //if (event.origin !== "https://dynapictures.com") return;
      const { type, payload } = event.data;

      if (type === "dynapictures/widgets/form/events/generation_finished") {
        //console.log("generation_finished:", payload);
        callback && callback(payload);
      }
    });
  }
};

const formWidgetLoader = () => {
  const elements = document.querySelectorAll(".dynapictures-form-widget");
  if (!elements) return;

  elements.forEach((el) => {
    const attrs = el.attributes;
    const props = attrToObj(attrs);

    for (const key in props) {
      if (key.indexOf("data") === 0 && key.length > 5) {
        const newKey = key[4].toLowerCase() + key.substring(5);
        props[newKey] = props[key];
        delete props[key];
      }
    }
    console.log("DynaPictures: Discovered widget props:", props);
    if (!props.formId) {
      el.innerHTML =
        "<div style='color: #842029;background-color:#f8d7da;border: 1px solid #f5c2c7;border-radius: 4px;padding: 1rem;'>Cannot load DynaPictures widget. The 'form-id' parameter is missing.<div>";
      return;
    }

    const script = document.getElementById("dynapictures-form-loader");
    const parser = document.createElement("a");
    parser.href = script.src;
    props.origin = parser.origin;

    ReactDOM.render(<FormWidget {...props} />, el);
  });
};

export default formWidgetLoader;
